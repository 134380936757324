<template>
  <div class="movie-translator">
    <header>
      <h1><i class="fas fa-film"></i> كىنو تەرجىمە ياردەمچىسى</h1>
    </header>

    <div class="card">
            <div class="input-group">
              <label for="movie-title">
                <i class="fas fa-heading"></i> كىنو ئىسمى:
              </label>
              <input id="movie-title" v-model="movieTitle" type="text" placeholder="كىنو ئىسمىنى كىرگۈزۈڭ">
            </div>

      <div class="input-group">
        <label for="movie-description">
          <i class="fas fa-info-circle"></i> قىسقىچە چۈشەندۈرۈش:
        </label>
        <textarea id="movie-description" v-model="movieDescription"
                  placeholder="قىسقىچە مەزمۇنىنى كىرگۈزۈڭ (ئىختىيارى)"></textarea>
      </div>
    </div>

    <div class="card">
      <button @click="showSettingsDialog = true" class="btn btn-settings">
        <i class="fas fa-cog"></i> prompt تەڭشىكى
      </button>
    </div>


    <div class="card artists-section">
      <h2><i class="fas fa-users"></i> ئارتىسلار</h2>
      <ul>
        <li v-for="(artist, index) in artists" :key="index" class="artist-item">
          <span>{{ artist.name }} - {{ artist.translatedName }}</span>
          <button @click="removeArtist(index)" class="btn btn-remove">
            <i class="fas fa-trash-alt"></i>
          </button>
        </li>
      </ul>
      <div class="add-artist">
        <input v-model="newArtistName" placeholder="ئارتىسنىڭ ئىسمى">
        <input v-model="newArtistTranslatedName" placeholder="تەرجىمە قىلىنغان ئىسىم">
        <button @click="addArtist" class="btn btn-add">
          <i class="fas fa-plus"></i> يېڭى ئارتىس قوشۇش
        </button>
      </div>
    </div>

    <div class="card file-upload">
      <label for="subtitle-file">
        <i class="fas fa-file-upload"></i> كىنونىڭ خەت ھۆججىتىنى تاللاڭ:
      </label>
      <input id="subtitle-file" type="file" @change="handleFileUpload">
    </div>

    <div class="progress-container">
      <div class="progress-bar">
        <div class="progress" :style="{ width: `${translationProgress}%` }"></div>
      </div>
      <span class="progress-text">{{ translationProgress }}% تاماملاندى</span>
    </div>

    <div class="translation-controls">
      <button @click="startTranslation" :disabled="!subtitles.length || isTranslating" class="btn btn-primary">
        <i class="fas fa-language"></i> AI ئارقىلىق تەرجىمە قىلىش
      </button>

      <button v-if="isTranslating" @click="pauseTranslation" class="btn btn-warning">
        <i class="fas fa-pause"></i> توختىتىش
      </button>

      <button v-if="isPaused" @click="resumeTranslation" class="btn btn-success">
        <i class="fas fa-play"></i> داۋاملاشتۇرۇش
      </button>
    </div>

    <div v-if="subtitles.length" class="card subtitles-section">
      <h2><i class="fas fa-closed-captioning"></i> تەرجىمە نەتىجىسى</h2>
      <div v-for="subtitle in subtitles" :key="subtitle.index" class="subtitle-item">
        <div class="subtitle-header">
          <span class="subtitle-timecode">{{ subtitle.timeCode }}</span>
          <button @click="translateSingle(subtitle)" class="btn btn-translate-single">
            <i class="fas fa-sync"></i> تەرجىمە قىلىش
          </button>
        </div>
        <div class="subtitle-content">
          <textarea class="ltr" v-model="subtitle.originalText" placeholder="ئەسلى تېكىست" readonly></textarea>
          <textarea class="rtl" v-model="subtitle.translatedText" placeholder="تەرجىمە نەتىجىسى"></textarea>
        </div>
      </div>
    </div>

    <button @click="saveSubtitles" :disabled="!subtitles.length" class="btn btn-save">
      <i class="fas fa-save"></i> خەت ھۆججىتىنى ساقلاش
    </button>


    <!-- تەڭشەك دىئالوگى -->
    <div v-if="showSettingsDialog" class="dialog-overlay">
      <div class="dialog">
        <h2>سىستېما تەڭشەكلىرى</h2>
        <label for="system-message">سىستېما ئۇچۇرى:</label>
        <textarea id="system-message" v-model="tempSystemMessageContent" rows="10" class="system-message-input"></textarea>

        <label for="batch-size">توپ چوڭلۇقى:</label>
        <input id="batch-size" v-model.number="BatchSize" type="number" min="1" max="100" class="batch-size-input">

        <div class="dialog-buttons">
          <button @click="saveSettings" class="btn btn-primary">ساقلاش</button>
          <button @click="cancelSettings" class="btn btn-secondary">ۋاز كېچىش</button>
        </div>
      </div>
    </div>


  </div>


</template>

<script>
import {ref, reactive, onMounted} from 'vue';

export default {
  setup() {
    const movieTitle = ref('');
    const movieDescription = ref('');
    const artists = reactive([]);
    const newArtistName = ref('');
    const newArtistTranslatedName = ref('');
    const subtitles = ref([]);
    const translationProgress = ref(0);
    const isTranslating = ref(false);
    const isPaused = ref(false);
    let currentBatchIndex = 0;
    const BatchSize = ref(30);

    const showSettingsDialog = ref(false);
    const systemMessageContent = ref(
        "سىز بىر كىنو تېكىستى تەرجىمە ياردەمچىسى، مەن سىزگە كىنو تېكىستىنى يوللايمەن سىز ئۇيغۇرچىغا تەرجىمە قىلىسىز. بۇنى ئۇيغۇرچىغا تەرجىمە قىلغاندا شۇنداق قىلغىنكى، گويا ئۇيغۇرلار ئارىسىدا چوڭ بولغان بىرى تەرجىمە قىلغاندەك چىقسۇن. سۆزمۇ سۆز تەرجىمە قىلماي، مەنىسىنى چىقىرىپ، ئۇيغۇرچە تەپەككۇر بىلەن يېڭىۋاشتىن يازغىن. گەپلىرىڭ ئېغىزدىن چىققاندەك تەبىئىي، روھى بار، جانلىق بولسۇن. ماقال-تەمسىل، ئىبارىلەرنى جايىدا ئىشلەتكىن. ئاددىي خەلقنىڭ تىلى بىلەن سۆزلىگەندەك ساددا، ئەمما مەنىسى چوڭقۇر بولسۇن. تەرجىمەڭنى كۆرگەن كىشى \"ئۇيغۇرنىڭ قولىدىن چىققان\" دەپ ئويلاپ قالغۇدەك دەرىجىدە ئۇيغۇرچە پۇراپ تۇرسۇن. ئەسلى نۇسخىدىكى روھنى ساقلاپ قېلىپ، ئۇنى ئۇيغۇرچە قېلىپقا قۇيۇپ چىققىن. قىسقىسى، بۇ تەرجىمە ئەمەس، بەلكى قايتا يېزىش بولۇپ چىقسۇن. جاۋابىڭغا ھېچقانداق مۇناسىۋەتسىز مەزمۇن قوشۇلمىسۇن.\n" +
        "ئەگەر تەرجىمە قىلىشقا پەقەتلا ئىمكانىيەت يوق ياكى نىزاملارغا ئۇيغۇن كەلمىسە، ئەسلى تېكىستنى بىۋاستە قايتۇرۇپ بەرسەڭلا بولىدۇ."
    );
    const tempSystemMessageContent = ref('');


    onMounted(() => {
      const savedSystemMessage = localStorage.getItem('systemMessageContent');

      if (savedSystemMessage) {
        systemMessageContent.value = savedSystemMessage;
        tempSystemMessageContent.value = savedSystemMessage;
      }else{
        localStorage.setItem('systemMessageContent', systemMessageContent.value);
      }
      tempSystemMessageContent.value = systemMessageContent.value;


      // 从本地存储中读取BatchSize
      const savedBatchSize = localStorage.getItem('BatchSize');
      if (savedBatchSize) {
        BatchSize.value = parseInt(savedBatchSize);
      } else {
        // 如果没有保存过，则使用默认值30
        localStorage.setItem('BatchSize', BatchSize.value.toString());
      }

    });

    const openSettingsDialog = () => {
      tempSystemMessageContent.value = systemMessageContent.value;
      showSettingsDialog.value = true;
    };

    const saveSettings = () => {
      systemMessageContent.value = tempSystemMessageContent.value;
      localStorage.setItem('systemMessageContent', systemMessageContent.value);
      localStorage.setItem('BatchSize', BatchSize.value.toString());
      showSettingsDialog.value = false;
    };

    const cancelSettings = () => {
      tempSystemMessageContent.value = systemMessageContent.value;
      showSettingsDialog.value = false;
    };

    const addArtist = () => {
      if (newArtistName.value && newArtistTranslatedName.value) {
        artists.push({
          name: newArtistName.value,
          translatedName: newArtistTranslatedName.value
        });
        newArtistName.value = '';
        newArtistTranslatedName.value = '';
      }
    };

    const removeArtist = (index) => {
      artists.splice(index, 1);
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        subtitles.value = parseSubtitles(reader.result);
      };

      reader.readAsText(file);
    };

    const parseSubtitles = (fileContent) => {
      const parsedSubtitles = [];
      const lines = fileContent.split('\n');
      let currentSubtitle = null;

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (!isNaN(parseInt(line)) && !line.includes(':')) {
          if (currentSubtitle) {
            parsedSubtitles.push(currentSubtitle);
          }
          currentSubtitle = {
            index: parseInt(line),
            timeCode: '',
            originalText: '',
            translatedText: '',
            emptyLines: 0
          };
        } else if (line.includes('-->')) {
          currentSubtitle.timeCode = line;
        } else if (line === '') {
          if (currentSubtitle) {
            currentSubtitle.emptyLines++;
          }
        } else {
          currentSubtitle.originalText += (currentSubtitle.originalText ? '\n' : '') + line;
        }
      }

      if (currentSubtitle) {
        parsedSubtitles.push(currentSubtitle);
      }

      return parsedSubtitles;
    };

    const startTranslation = async () => {
      isTranslating.value = true;
      isPaused.value = false;
      currentBatchIndex = 0;
      await translateWithAI();
    };

    const pauseTranslation = () => {
      isPaused.value = true;
    };

    const resumeTranslation = async () => {
      isPaused.value = false;
      await translateWithAI();
    };

    const translateWithAI = async () => {
      const apiUrl = 'https://chat-ai.quxray.cn/v1/chat/completions';

      let artistsText = "";
      if (artists.length > 0) {
        artistsText = "كىشى ئىسىملىرىنى تۆۋەندە كۆرسىتىلگەن بويىچە تەرجىمە قىلىڭ:\n";
        artistsText += artists.map(artist => `${artist.name}: ${artist.translatedName}`).join("\n");
      }

      const systemMessage = {
        role: "system",
        content: `${systemMessageContent.value}
${artistsText}

تۆۋەندىكى JSON قۇرۇلمىسىنى ساقلىغان ئاساستا تەرجىمە قىلىڭ. جاۋابنى پەقەت JSON فورماتىدا قايتۇرۇڭ، باشقا ھېچقانداق چۈشەندۈرۈش ياكى ئىزاھات قوشماڭ. ھەر بىر تەرجىمە قىلىنغان تۈرنىڭ index ۋە translatedText خاسلىقى بولۇشى كېرەك. مەسىلەن:

{
  "translations": [
    {
      "index": 1,
      "translatedText": "ئۇيغۇرچە تەرجىمە"
    },
    {
      "index": 2,
      "translatedText": "يەنە بىر ئۇيغۇرچە تەرجىمە"
    }
  ]
}
`
      };

      const totalBatches = Math.ceil(subtitles.value.length / BatchSize.value);
      // پاراڭلىشىش تارىخىنى ساقلاش ئۈچۈن يېڭى ئۆزگەرگۈچى
      let conversationHistory = [systemMessage];

      for (let batchIndex = currentBatchIndex; batchIndex < totalBatches; batchIndex++) {
        if (isPaused.value) {
          currentBatchIndex = batchIndex;
          return;
        }

        const start = batchIndex * BatchSize.value;
        const end = Math.min((batchIndex + 1) * BatchSize.value, subtitles.value.length);
        const batch = subtitles.value.slice(start, end);

        const nonEmptyBatch = batch.filter(subtitle => subtitle.originalText.trim() !== '');
        const batchContent = JSON.stringify({
          subtitles: nonEmptyBatch.map(subtitle => ({
            index: subtitle.index,
            originalText: subtitle.originalText
          }))
        });

        const userMessage = { role: "user", content: batchContent };
        conversationHistory.push(userMessage);

        // پاراڭلىشىش تارىخىنىڭ ئۇزۇنلۇقىنى چەكلەش (مەسىلەن، ئەڭ يېڭى 50 پارچە)
        if (conversationHistory.length > 51) {  // سىستېم ئۇچۇرى + 50 پارچە پاراڭ
          conversationHistory = [systemMessage, ...conversationHistory.slice(-50)];
        }

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              messages: conversationHistory
            })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("API response:", data);



          let translations;
          try {
            // AI نىڭ جاۋابىنى پاراڭلىشىش تارىخىغا قوشۇش
            conversationHistory.push(data.choices[0].delta);
            console.log(data.choices[0].delta.content);
            console.log(typeof data.choices[0].delta.content);
            let parsedContent = formatJSON(data.choices[0].delta.content);
            translations = parsedContent.translations;
            if (!Array.isArray(translations)) {
              throw new Error("Translations is not an array");
            }
          } catch (parseError) {
            console.error("JSON parsing error or invalid format:", parseError);
            throw parseError;
          }

          for (let translation of translations) {
            const subtitle = batch.find(s => s.index === translation.index);
            if (subtitle) {
              subtitle.translatedText = translation.translatedText;
            }
          }

          translationProgress.value = ((batchIndex + 1) / totalBatches) * 100;
        } catch (error) {
          console.error("Translation error:", error);
          for (let subtitle of batch) {
            subtitle.translatedText = "تەرجىمە مەغلۇپ بولدى. قايتا سىناڭ.";
          }
        }
      }

      translationProgress.value = 100;
      isTranslating.value = false;
    };


    function formatJSON(jsonString) {
      try {
        // يانتۇ سىزىقلارنى ئېلىۋېتىش
        jsonString = jsonString.replace(/\\"/g, '"');

        // translatedText نىڭ مەزمۇنىدىكى قۇر تاشلاشنى \n غا ئۆزگەرتىش
        jsonString = jsonString.replace(/"translatedText": "(.+?)"/gs, function(match, p1) {
          return '"translatedText": "' + p1.replace(/\n/g, '\\n') + '"';
        });



        // JSON نى تەھلىل قىلىش
        const jsonObj = JSON.parse(jsonString);


        return jsonObj;
      } catch (error) {
        return "JSON تەھلىل قىلىش خاتالىقى: " + error.message;
      }
    }




    const translateSingle = async (subtitle) => {

      const apiUrl = 'https://chat-ai.quxray.cn/v1/chat/completions';

      let artistsText = "";
      if (artists.length > 0) {
        artistsText = "كىشى ئىسىملىرىنى تۆۋەندە كۆرسىتىلگەن بويىچە تەرجىمە قىلىڭ:\n";
        artistsText += artists.map(artist => `${artist.name}: ${artist.translatedName}`).join("\n");
      }

      const systemMessage = {
        role: "system",
        content: systemMessageContent.value + (artistsText ? "\n\n" + artistsText : "")
      };

      const messages = [
        systemMessage,
        {role: "user", content: subtitle.originalText}
      ];

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            messages: messages
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API response for single translation:", data);

        subtitle.translatedText = data.choices[0].delta.content;
      } catch (error) {
        console.error("Translation error:", error);
        subtitle.translatedText = "تەرجىمە مەغلۇپ بولدى. قايتا سىناڭ.";
      }
    };

    const saveSubtitles = () => {
      let output = '';
      for (let subtitle of subtitles.value) {
        output += `${subtitle.index}\n`;

        // ۋاقىت ئوقىنى ئۆزگەرتىش
        const modifiedTimeCode = subtitle.timeCode.replace(/(\d{2}:\d{2}:\d{2})\.(\d{3})/g, '$1,$2');
        output += `${modifiedTimeCode}\n`;

        if (subtitle.translatedText.trim()) {
          output += `${subtitle.translatedText}\n`;
        } else {
          output += `${subtitle.originalText}\n`;
        }
        output += '\n';  // ھەر بىر subtitle دىن كېيىن بىر قۇرۇق قۇر قوشۇش
      }

      const blob = new Blob([output], {type: 'text/plain;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${movieTitle.value || 'subtitle'}_translated.srt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };



    return {
      BatchSize,
      movieTitle,
      movieDescription,
      artists,
      newArtistName,
      newArtistTranslatedName,
      subtitles,
      translationProgress,
      isTranslating,
      isPaused,
      showSettingsDialog,
      systemMessageContent,
      tempSystemMessageContent,
      addArtist,
      removeArtist,
      handleFileUpload,
      startTranslation,
      pauseTranslation,
      resumeTranslation,
      translateSingle,
      saveSubtitles,
      openSettingsDialog,
      saveSettings,
      cancelSettings
    };
  }
}
</script>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap');
@import "assets/font.css";

button, input, textarea, label {
  font-family: 'UKIJ Tuz Tom', sans-serif;
}

.movie-translator {
  direction: rtl;
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  font-family: 'UKIJ Tuz Tom', sans-serif;
  color: #333;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}

header {
  text-align: center;
  margin-bottom: 30px;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

h1, h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #34495e;
}

input, textarea {
  width: 95%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  border-color: #3498db;
  outline: none;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.btn {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 16px;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #3498db;
  color: white;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-add {
  background-color: #2ecc71;
  color: white;
}

.btn-add:hover {
  background-color: #27ae60;
}

.btn-remove {
  background-color: #e74c3c;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
}

.btn-remove:hover {
  background-color: #c0392b;
}

.btn-save {
  background-color: #f39c12;
  color: white;
  width: 100%;
  margin-top: 20px;
}

.btn-save:hover {
  background-color: #d35400;
}

.artists-section ul {
  list-style-type: none;
  padding: 0;
}

.artist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.artist-item:hover {
  background-color: #e9ecef;
}

.add-artist {
  display: flex;
  gap: 12px;
  margin-top: 15px;
}

.add-artist input {
  flex: 1;
}

.subtitle-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.subtitle-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-weight: bold;
  color: #34495e;
}

.subtitle-timecode {
  color: #7f8c8d;
  font-family: monospace;
}

.subtitle-content {
  display: flex;
  gap: 15px;
}

.subtitle-content textarea {
  flex: 1;
  min-height: 80px;
}

.file-upload input[type="file"] {
  display: block;
  margin-top: 12px;
  padding: 12px;
  background-color: #f8f9fa;
  border: 2px dashed #bdc3c7;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-upload input[type="file"]:hover {
  border-color: #3498db;
}

.progress-container {
  margin-bottom: 20px;
}

.progress-bar {
  width: 100%;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #3498db;
  transition: width 0.3s ease;
}

.progress-text {
  display: block;
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
  color: #34495e;
}

.translation-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.translation-controls button {
  flex: 1;
  margin: 0 8px;
}

.btn-warning {
  background-color: #f1c40f;
  color: #34495e;
}

.btn-warning:hover {
  background-color: #f39c12;
}

.btn-success {
  background-color: #2ecc71;
  color: white;
}

.btn-success:hover {
  background-color: #27ae60;
}

.btn-translate-single {
  background-color: #9b59b6;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
}

.btn-translate-single:hover {
  background-color: #8e44ad;
}

@media (max-width: 768px) {
  .movie-translator {
    padding: 20px;
  }

  .add-artist {
    flex-direction: column;
  }

  .subtitle-content {
    flex-direction: column;
  }

  .translation-controls {
    flex-direction: column;
  }

  .translation-controls button {
    margin: 8px 0;
  }
}

.btn-settings {
  background-color: #34495e;
  color: white;
  margin-bottom: 20px;
}

.btn-settings:hover {
  background-color: #2c3e50;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.system-message-input {
  width: 95%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'UKIJ Tuz Tom', sans-serif;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn-secondary {
  background-color: #95a5a6;
  color: white;
}

.btn-secondary:hover {
  background-color: #7f8c8d;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.batch-size-input{
  margin-bottom: 20px;
}
</style>